import { gsap } from 'gsap';

import { inview } from '../../utils/inview';

export default class HeadingSection {
  constructor() {
    this.el = document.querySelectorAll('.heading-section');
  }

  mounted() {
    gsap.ticker.add(this.onRaf);

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;
  }

  onRaf = () => {
    this.el.forEach((el) => {
      if (inview(el, this.windowHeight / 2, 0)) {
        if (!el.inview) {
          const path = el.querySelectorAll('path');

          el.inview = true;

          gsap.fromTo(
            path,
            { scale: 0, rotate: -90 },
            { scale: 1, rotate: 0, duration: 3, stagger: 0.1, ease: 'expo.out' },
          );

          gsap.set(path, { autoAlpha: 1 });
        }
      }
    });
  }
}
