import { app } from '../../utils/store';

export default class SignUp {
  constructor() {
    this.el = document.querySelector('.s-sign-up');

    this.form = this.el.querySelector('form');
  }
  
  mounted() {
    if (!this.form) {
      app.highway.redirect(`${document.location.origin}/shows/`);
    } else {
      this.form.addEventListener("submit", this.onSubmit);
    }
  }

  destroyed = () => { }

  async onSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const status = form.querySelector('.status');

    const data = new FormData(e.target);

    fetch(e.target.action, {
      method: form.method,
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        app.highway.redirect(`${document.location.origin}/thank-you/`);
        form.reset();
      } else {
        response.json().then(data => {
          if (Object.hasOwn(data, 'errors')) {
            status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
          } else {
            status.innerHTML = "Oops! There was a problem submitting your form"
          }
        })
      }
    }).catch(error => {
      console.log(error);
    });
  }
}
