import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import { lerp, clamp } from '../../utils/math';

export default class History {
  constructor() {
    this.el = document.querySelector('.s-history');

    this.container = this.el.querySelector('.container');

    this.dragging = false;

    this.x = {
      on: 0,
      off: 0,
      min: 0,
      max: 0,
      last: 0,
      current: 0,
    };

    this.options = {
      ease: 0.1,
      speed: 1.5
    };
  }

  mounted() {
    gsap.ticker.add(this.onRaf);

    this.onResize();
    this.setBounds();
    this.addEvents();
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);
  }

  addEvents = () => {
    this.container.addEventListener('mouseup', this.disableDrag);
    this.container.addEventListener('mousedown', this.enableDrag);
    this.container.addEventListener('mouseleave', this.disableDrag);
    this.container.addEventListener('mousemove', this.setPosition);
    this.container.addEventListener('touchstart', this.enableDrag);
    this.container.addEventListener('touchmove', this.setPosition);
    this.container.addEventListener('touchend', this.disableDrag);
  }

  onRaf = () => {
    this.x.last = lerp(this.x.last, this.x.current, this.options.ease);
    this.container.style.transform = `translate3d(${this.x.last}px, 0, 0)`;
  }

  onResize = () => {
    this.setBounds();
  }

  setBounds = () => {
    this.bounds = this.container.getBoundingClientRect();

    const width = this.bounds.width;

    this.x.max = width - window.innerWidth;
  }

  setPosition = (e) => {
    if (!this.dragging) return;

    const x = Sniffer.isDevice ? e.touches[0].clientX : e.clientX;

    this.x.current = this.x.off + ((x - this.x.on) * this.options.speed);
    this.x.current = clamp(this.x.current, -this.x.max, this.x.min);
  }

  enableDrag = (e) => {
    this.dragging = true;
    this.x.on = Sniffer.isDevice ? e.touches[0].clientX : e.clientX;
    this.el.classList.add('is-dragging');
  }

  disableDrag = () => {
    this.dragging = false;
    this.x.off = this.x.current;
    this.el.classList.remove('is-dragging');
  }
}
