import gsap from 'gsap';
import Sniffer from 'sniffer';

export default class Segments {
  constructor() {
    this.el = document.querySelector('.s-segments');

    this.card = this.el.querySelectorAll('.card');
    this.heading = this.el.querySelectorAll('.heading');
    this.text = this.el.querySelectorAll('.text');
    this.image = this.el.querySelectorAll('.image');
    this.img = this.el.querySelectorAll('.img');
  }

  mounted() {
    if (Sniffer.isDevice) return;

    this.addEvents();
  }

  destroyed = () => { }

  addEvents = () => {
    this.card.forEach((card, i) => {
      card.addEventListener('mouseenter', () => {
        gsap.to(
          this.heading[i],
          { y: -window.innerWidth * 0.05, duration: 1, ease: 'power3.out' }
        );
        gsap.to(
          this.text[i],
          { y: -window.innerWidth * 0.05, duration: 1, autoAlpha: 1, ease: 'power3.out' }
        );
        gsap.to(
          this.image[i],
          { maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))" }
        );
        gsap.to(
          this.img[i],
          { scale: 1.1, duration: 1, autoAlpha: 1, ease: 'power3.out' }
        );
      });

      card.addEventListener('mouseleave', () => {
        gsap.to(
          this.heading[i],
          { y: 0, duration: 1, ease: 'power3.out' }
        );
        gsap.to(
          this.text[i],
          { y: 0, autoAlpha: 0, duration: 1, ease: 'power3.out' }
        );
        gsap.to(
          this.image[i],
          { maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))" }
        );
        gsap.to(
          this.img[i],
          { scale: 1, duration: 1, autoAlpha: 1, ease: 'power3.out' }
        );
      });
    });
  }
}
