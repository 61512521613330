import { gsap } from 'gsap';

export default class SocialShare {
  constructor() {
    this.el = document.querySelector('.social-share');

    this.link = this.el.querySelector('[data-link]');
    this.svg = this.el.querySelector('svg');
  }

  mounted() {
    this.link.addEventListener('click', () => {
      const link = this.link.dataset.link;

      navigator.clipboard.writeText(link);

      gsap.fromTo(
        this.svg,
        { rotate: 0 },
        { rotate: 180 }
      )
    });
  }

  destroyed = () => { }
}
