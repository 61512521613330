// store
import { app } from '../utils/store';

// components
import Background from '../components/background/background';
import Button from '../components/button/button';
import Collage from '../components/collage/collage';
import Disciplines from '../components/disciplines/disciplines';
import Dragger from '../components/dragger/dragger';
import Footer from '../components/footer/footer';
import Form from '../components/form/form';
import HeaderArticle from '../components/header-article/header-article';
import HeaderHome from '../components/header-home/header-home';
import HeaderShow from '../components/header-show/header-show';
import HeaderText from '../components/header-text/header-text';
import HeadingSection from '../components/heading-section/heading-section';
import History from '../components/history/history';
import Image from '../components/image/image';
import InsightsNav from '../components/insights-nav/insights-nav';
import InsightsPost from '../components/insights-post/insights-post';
import Media from '../components/media/media';
import Newsletter from '../components/newsletter/newsletter';
import Process from '../components/process/process';
import Promises from '../components/promises/promises';
import Segments from '../components/segments/segments';
import Shows from '../components/shows/shows';
import ShowsArchive from '../components/shows-archive/shows-archive';
import ShowsFeatured from '../components/shows-featured/shows-featured';
import ShowsFilter from '../components/shows-filter/shows-filter';
import ShowsNav from '../components/shows-nav/shows-nav';
import ShowsSelect from '../components/shows-select/shows-select';
import SignIn from '../components/sign-in/sign-in';
import SignUp from '../components/sign-up/sign-up';
import SocialShare from '../components/social-share/social-share';
import SoundProduction from '../components/sound-production/sound-production';
import Text from '../components/text/text';
import Video from '../components/video/video';
import Whitepaper from '../components/whitepaper/whitepaper';

// ui
import Hud from '../utils/hud';
import Scroll from '../utils/scroll';

// components
export default class Components {
  constructor() {
    this.background = document.querySelector('.a-background');
    this.button = document.querySelector('[data-button]');
    this.collage = document.querySelector('.s-collage');
    this.disciplines = document.querySelector('.s-disciplines');
    this.dragger = document.querySelectorAll('.s-dragger');
    this.footer = document.querySelector('.a-footer');
    this.form = document.querySelector('.s-form');
    this.headerArticle = document.querySelector('.header-article');
    this.headerHome = document.querySelector('.header-home');
    this.headerShow = document.querySelector('.header-show');
    this.headerText = document.querySelector('.header-text');
    this.headingSection = document.querySelector('.heading-section');
    this.history = document.querySelector('.s-history');
    this.image = document.querySelector('.s-image');
    this.insightsNav = document.querySelector('.s-insights-nav');
    this.insightsPost = document.querySelector('.insights-post');
    this.media = document.querySelector('.s-media');
    this.newsletter = document.querySelector('.s-newsletter');
    this.process = document.querySelector('.s-process');
    this.promises = document.querySelector('.s-promises');
    this.segments = document.querySelector('.s-segments');
    this.shows = document.querySelector('.s-shows');
    this.showsArchive = document.querySelector('.s-shows-archive');
    this.showsFeatured = document.querySelector('.s-shows-featured');
    this.showsFilter = document.querySelector('.a-shows-filter');
    this.showsNav = document.querySelector('.a-shows-nav');
    this.showsSelect = document.querySelector('.s-shows-select');
    this.signIn = document.querySelector('.s-sign-in');
    this.signUp = document.querySelector('.s-sign-up');
    this.socialShare = document.querySelector('.social-share');
    this.soundProduction = document.querySelector('.s-sound-production');
    this.text = document.querySelectorAll('.s-text');
    this.video = document.querySelector('.s-video');
    this.whitepaper = document.querySelector('.s-whitepaper');
  }

  destroyed = () => {
    app.hud.destroyed();
    app.scroll.destroyed();

    if (this.background) { this.background.destroyed(); }
    if (this.button) { this.button.destroyed(); }
    if (this.collage) { this.collage.destroyed(); }
    if (this.disciplines) { this.disciplines.destroyed(); }
    if (this.dragger) {
      this.draggerArray.forEach((dragger) => {
        dragger.destroyed();
      });
    }
    if (this.footer) { this.footer.destroyed(); }
    if (this.form) { this.form.destroyed(); }
    if (this.headerArticle) { this.headerArticle.destroyed(); }
    if (this.headerHome) { this.headerHome.destroyed(); }
    if (this.headerShow) { this.headerShow.destroyed(); }
    if (this.headerText) { this.headerText.destroyed(); }
    if (this.headingSection) { this.headingSection.destroyed(); }
    if (this.history) { this.history.destroyed(); }
    if (this.image) { this.image.destroyed(); }
    if (this.insightsNav) { this.insightsNav.destroyed(); }
    if (this.insightsPost) { this.insightsPost.destroyed(); }
    if (this.media) { this.media.destroyed(); }
    if (this.newsletter) { this.newsletter.destroyed(); }
    if (this.process) { this.process.destroyed(); }
    if (this.promises) { this.promises.destroyed(); }
    if (this.segments) { this.segments.destroyed(); }
    if (this.shows) { this.shows.destroyed(); }
    if (this.showsArchive) { this.showsArchive.destroyed(); }
    if (this.showsFeatured) { this.showsFeatured.destroyed(); }
    if (this.showsFilter) { this.showsFilter.destroyed(); }
    if (this.showsNav) { this.showsNav.destroyed(); }
    if (this.showsSelect) { this.showsSelect.destroyed(); }
    if (this.signIn) { this.signIn.destroyed(); }
    if (this.signUp) { this.signUp.destroyed(); }
    if (this.socialShare) { this.socialShare.destroyed(); }
    if (this.soundProduction) { this.soundProduction.destroyed(); }
    if (this.text) {
      this.textArray.forEach((text) => {
        text.destroyed();
      });
    }
    if (this.video) { this.video.destroyed(); }
    if (this.whitepaper) { this.whitepaper.destroyed(); }
  }

  mounted = () => {
    app.hud = new Hud();
    app.hud.mounted();

    app.scroll = new Scroll();
    app.scroll.mounted();

    if (this.background) {
      this.background = new Background();
      this.background.mounted();
    }

    if (this.button) {
      this.button = new Button();
      this.button.mounted();
    }

    if (this.collage) {
      this.collage = new Collage();
      this.collage.mounted();
    }

    if (this.disciplines) {
      this.disciplines = new Disciplines();
      this.disciplines.mounted();
    }

    this.draggerArray = [];

    if (this.dragger) {
      this.dragger.forEach((dragger) => {
        dragger = new Dragger(dragger);
        dragger.mounted();
        this.draggerArray.push(dragger);
      });
    }

    if (this.footer) {
      this.footer = new Footer();
      this.footer.mounted();
    }

    if (this.form) {
      this.form = new Form();
      this.form.mounted();
    }

    if (this.headerArticle) {
      this.headerArticle = new HeaderArticle();
      this.headerArticle.mounted();
    }

    if (this.headerHome) {
      this.headerHome = new HeaderHome();
      this.headerHome.mounted();
    }

    if (this.headerText) {
      this.headerText = new HeaderText();
      this.headerText.mounted();
    }

    if (this.headerShow) {
      this.headerShow = new HeaderShow();
      this.headerShow.mounted();
    }

    if (this.headingSection) {
      this.headingSection = new HeadingSection();
      this.headingSection.mounted();
    }

    if (this.history) {
      this.history = new History();
      this.history.mounted();
    }

    if (this.image) {
      this.image = new Image();
      this.image.mounted();
    }

    if (this.insightsNav) {
      this.insightsNav = new InsightsNav();
      this.insightsNav.mounted();
    }

    if (this.insightsPost) {
      this.insightsPost = new InsightsPost();
      this.insightsPost.mounted();
    }

    if (this.media) {
      this.media = new Media();
      this.media.mounted();
    }

    if (this.newsletter) {
      this.newsletter = new Newsletter();
      this.newsletter.mounted();
    }

    if (this.process) {
      this.process = new Process();
      this.process.mounted();
    }

    if (this.promises) {
      this.promises = new Promises();
      this.promises.mounted();
    }

    if (this.segments) {
      this.segments = new Segments();
      this.segments.mounted();
    }

    if (this.shows) {
      this.shows = new Shows();
      this.shows.mounted();
    }

    if (this.showsArchive) {
      this.showsArchive = new ShowsArchive();
      this.showsArchive.mounted();
    }

    if (this.showsFeatured) {
      this.showsFeatured = new ShowsFeatured();
      this.showsFeatured.mounted();
    }

    if (this.showsFilter) {
      this.showsFilter = new ShowsFilter();
      this.showsFilter.mounted();
    }

    if (this.showsNav) {
      this.showsNav = new ShowsNav();
      this.showsNav.mounted();
    }

    if (this.showsSelect) {
      this.showsSelect = new ShowsSelect();
      this.showsSelect.mounted();
    }

    if (this.signIn) {
      this.signIn = new SignIn();
      this.signIn.mounted();
    }

    if (this.signUp) {
      this.signUp = new SignUp();
      this.signUp.mounted();
    }

    if (this.socialShare) {
      this.socialShare = new SocialShare();
      this.socialShare.mounted();
    }

    if (this.soundProduction) {
      this.soundProduction = new SoundProduction();
      this.soundProduction.mounted();
    }

    this.textArray = [];

    if (this.text) {
      this.text.forEach((text) => {
        text = new Text(text);
        text.mounted();
        this.textArray.push(text);
      });
    }

    if (this.video) {
      this.video = new Video();
      this.video.mounted();
    }

    if (this.whitepaper) {
      this.whitepaper = new Whitepaper();
      this.whitepaper.mounted();
    }
  }
}