import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';

import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class showsArchive {
  constructor() {
    this.el = document.querySelector('.s-shows-archive');

    this.container = this.el.querySelector('.container');

    this.nav = document.querySelector('.a-shows-nav');
    this.cursor = document.querySelector('.preview-video');
    this.background = document.querySelector('.a-background');

    const options = {
      mouseMultiplier: 0.2,
    }

    this.vs = new VirtualScroll(options);

    this.last = 0;
    this.ease = 0.1;
    this.current = 0;

    this.firstLoad = true;
  }

  mounted() {
    emitter.on('showsArchive: enter', this.enter);
    emitter.on('showsArchive: leave', this.leave);
    emitter.on('showsArchive: init', this.init);
    emitter.on('showsArchive: enterShows', this.enterShows);

    this.addEvents();

    if (Sniffer.isDevice) return;

    this.onResize();

    gsap.ticker.add(this.onRaf);
  }

  destroyed = () => {
    emitter.off('showsArchive: enter', this.enter);
    emitter.off('showsArchive: leave', this.leave);
    emitter.off('showsArchive: init', this.init);
    emitter.off('showsArchive: enterShows', this.enterShows);

    this.removeEvents();

    if (Sniffer.isDevice) return;

    gsap.ticker.remove(this.onRaf);

    this.vs.destroy();
  }

  addEvents = () => {
    this.showLink = this.el.querySelectorAll('.show-link');

    this.showLink.forEach(link => link.addEventListener('mouseenter', this.enterShow));
    this.showLink.forEach(link => link.addEventListener('mouseleave', this.leaveShow));

    window.addEventListener('resize', this.onResize, { passive: true });
  }

  removeEvents = () => {
    this.showLink.forEach(link => link.removeEventListener('mouseenter', this.enterShow));
    this.showLink.forEach(link => link.removeEventListener('mouseleave', this.leaveShow));

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  init = () => {
    this.removeEvents();
    this.addEvents();
  }

  enter = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 0, x: window.innerWidth },
      { autoAlpha: 1, x: 0, ease: 'expo.inOut', duration: 1.5 }
    );

    this.enterShows();

    this.el.style.pointerEvents = '';

    if (Sniffer.isDevice) return;

    this.vs.on(this.onScroll);
  }

  leave = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 1, x: 0 },
      { autoAlpha: 0, x: window.innerWidth, ease: 'expo.inOut', duration: 1.5 }
    );

    this.el.style.pointerEvents = 'none';

    if (Sniffer.isDevice) return;

    this.vs.off(this.onScroll);
  }

  enterShow = (el) => {
    this.gradient = `radial-gradient(50% 155% at 70% 55%, ${el.target.dataset.secondary} 0%, ${el.target.dataset.tertiary} 100%`;

    gsap.to(
      this.background,
      { duration: 0.5, background: this.gradient }
    );

    this.nav.style.setProperty('--color-secondary', el.target.dataset.secondary);
    this.cursor.style.setProperty('--color-secondary', el.target.dataset.secondary);
  }

  leaveShow = () => {
    this.gradient = `radial-gradient(50% 155% at 70% 55%, #01017e 0%, #040232 100%`;

    gsap.to(
      this.background,
      { duration: 0.5, background: this.gradient }
    );

    this.nav.style.setProperty('--color-secondary', '#01017e');
    this.cursor.style.setProperty('--color-secondary', '#01017e');
  }

  enterShows = () => {
    const years = this.el.querySelectorAll('.year');
    const shows = this.el.querySelectorAll('.show-link');

    if (years.length === 0 || shows.length === 0) return;

    gsap.killTweensOf([years, shows]);

    gsap.set([years, shows], { autoAlpha: 0 });

    gsap.fromTo(
      [years, shows],
      { autoAlpha: 0, yPercent: 10 },
      { autoAlpha: 1, yPercent: 0, stagger: 0.1, duration: 2, ease: 'expo.out' }
    );

    this.onResize();
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.bounding = this.el.getBoundingClientRect();
  }

  onScroll = (e) => {
    this.current += e.deltaY;
    this.current = Math.max((this.bounding.height - this.windowHeight) * -1, this.current);
    this.current = Math.min(0, this.current);
  }

  onRaf = () => {
    if (-this.last < 0.1) this.last = 0;

    this.last = lerp(this.last, this.current, this.ease);

    this.container.style.transform = `translate3d(0px, ${this.last}px, 0px)`;
  }
}
