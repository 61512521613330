import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class HeaderShow {
  constructor() {
    this.el = document.querySelector('.header-show');

    this.head = this.el.querySelector('.head');
    this.assignment = this.el.querySelector('.assignment');
  }

  mounted() {
    this.addEvents();

    this.enter();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.el.addEventListener('click', this.openPlayer);
  }

  removeEvents = () => {
    this.el.removeEventListener('click', this.openPlayer);
  }

  openPlayer = () => {
    const video = this.el.querySelector('[data-video]');

    if (!video) return;

    const src = video.dataset.video;

    emitter.emit('video-player: openPlayer', src);
  }

  enter = (delay = 0.5) => {
    gsap.fromTo(
      [
        this.head.children,
        this.assignment.children
      ],
      { autoAlpha: 0, y: window.innerWidth * 0.02 },
      { autoAlpha: 1, y: 0, duration: 2, stagger: 0.2, ease: 'expo.out', delay }
    );
  }
}
