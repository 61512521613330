import { gsap } from 'gsap';

import { inview } from '../../utils/inview';

export default class Process {
  constructor() {
    this.el = document.querySelector('.s-process');

    this.content = this.el.querySelectorAll('.content');

    this.count = 0;
  }

  mounted() {
    gsap.ticker.add(this.onRaf);
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    this.content.forEach((el) => {
      if (inview(el, window.innerHeight, 0)) {
        if (el.inview) return;

        this.enter(el);

        el.inview = true;

        this.count++;

        if (this.count == this.content.length) {
          this.destroyed();
        }
      }
    });
  }

  enter = (el) => {
    const text = el.querySelector('.text');
    const digit = el.querySelector('.digit');
    const heading = el.querySelector('.heading');

    gsap.fromTo(
      [digit, heading, text],
      { autoAlpha: 0, y: window.innerWidth * 0.05 },
      { autoAlpha: 1, y: 0, duration: 2, stagger: 0.2, ease: 'expo.out' }
    );
  }
}
