export default class Newsletter {
  constructor() {
    this.el = document.querySelector('.s-newsletter');

    this.form = this.el.querySelector('form');
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.form.addEventListener('submit', this.onSubmit);
  }

  removeEvents = () => {
    this.form.removeEventListener('submit', this.onSubmit);
  }

  async onSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const status = form.querySelector('.status');

    const data = new FormData(e.target);

    fetch(e.target.action, {
      method: form.method,
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        form.reset();

        status.innerHTML = 'Thanks for subscribing!';
      } else {
        response.json().then(data => {
          if (Object.hasOwn(data, 'errors')) {
            status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
          } else {
            status.innerHTML = "Oops! There was a problem submitting your form"
          }
        })
      }
    }).catch(error => {
      console.log(error);
    });
  }
}
