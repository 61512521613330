import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

import { app } from '../../utils/store';
import { group } from '../../utils/math';

export default class showsFilter {
  constructor() {
    this.el = document.querySelector('.a-shows-filter');

    this.menu = this.el.querySelector('.menu');
    this.filter = this.el.querySelector('.filter');
    this.overlay = this.el.querySelector('.overlay');

    this.archive = document.querySelector('[data-archive]');
    this.categories = this.el.querySelectorAll('[data-category]');

    this.category = 'All';

    this.menuVisible = false;
  }

  mounted() {
    this.fetchList();

    emitter.on('showsFilter: enter', this.enter);
    emitter.on('showsFilter: leave', this.leave);

    this.addEvents();
  }

  destroyed = () => {
    emitter.off('showsFilter: enter', this.enter);
    emitter.off('showsFilter: leave', this.leave);
  }

  addEvents = () => {
    this.categories.forEach((e) => {
      e.addEventListener('click', this.onFilter);
    });

    this.menu.addEventListener('click', this.toggleMenu);
    this.filter.addEventListener('click', this.toggleMenu);
  }

  enter = () => {
    gsap.fromTo(
      this.filter,
      { autoAlpha: 0 },
      { autoAlpha: 1, delay: 1 }
    );
  }

  leave = () => {
    gsap.fromTo(
      this.filter,
      { autoAlpha: 1 },
      { autoAlpha: 0 }
    );
  }

  toggleMenu = () => {
    if (this.menuVisible) {
      this.menuVisible = false;
      gsap.fromTo(
        this.menu,
        { autoAlpha: 1 },
        { autoAlpha: 0 }
      );
      if (Sniffer.isPhone) {
        gsap.fromTo(
          this.overlay,
          { autoAlpha: 1 },
          { autoAlpha: 0 }
        );
      }
    } else {
      this.menuVisible = true;
      gsap.fromTo(
        this.menu,
        { autoAlpha: 0 },
        { autoAlpha: 1 }
      );
      if (Sniffer.isPhone) {
        gsap.fromTo(
          this.overlay,
          { autoAlpha: 0 },
          { autoAlpha: 1 }
        );
      }
    }
  }

  onFilter = (e) => {
    this.category = e.target.dataset.category;

    const active = this.el.querySelector('.is-active');

    active.classList.remove('is-active');

    e.target.classList.add('is-active');

    this.fetchList();
  }

  fetchList = () => {
    this.archive.innerHTML = '';

    if (!app.shows.posts.length || !app.categoryShow.taxonomies) {
      setTimeout(() => {
        this.fetchList();
      }, 100);
    } else {
      this.filterList();
    }
  }

  filterList = () => {
    const shows = app.shows.posts;
    const categories = app.categoryShow.taxonomies;

    this.shows = [];

    shows.forEach((show) => {
      let categoryName;

      categories.forEach((category) => {
        if (category.id === show['category-show'][0]) {
          categoryName = category.name;
        };
      });

      const props = {
        id: show.id,
        link: show.link,
        year: show.acf.year,
        preview: show.acf.preview,
        title: show.title.rendered,
        location: show.acf.location,
        exclusive: show.acf.exclusive,
        secondary: show.acf.secondaryColor,
        tertiary: show.acf.tertiaryColor,
        category: categoryName
      };

      if (this.category === 'All' || this.category === 'Exclusive') {
        if (this.category === 'Exclusive' && !show.acf.exclusive) return;
      } else {
        if (this.category != categoryName) return;
      }

      this.shows.push(props);
    });

    this.shows = group(this.shows, 'year');

    this.showsOrder = Object.keys(this.shows).reverse();

    this.appendList();
  }

  appendList = () => {
    this.showsOrder.forEach(year => {
      const yearBlock = document.createElement('div');
      yearBlock.classList.add('year');

      const yearTitle = document.createElement('p');
      yearTitle.classList.add('year-title');
      yearTitle.innerHTML = year;
      yearBlock.appendChild(yearTitle);

      this.shows[year].forEach((show) => {
        const showLink = document.createElement('a');
        showLink.classList.add('show-link');
        showLink.href = show.link;

        showLink.dataset.cursorPreview = show.preview;
        showLink.dataset.secondary = show.secondary;
        showLink.dataset.tertiary = show.tertiary;

        const showList = document.createElement('ul');
        showList.classList.add('list');

        const showTitle = document.createElement('li');
        showTitle.classList.add('list-title');
        showTitle.innerHTML = show.title;
        showList.appendChild(showTitle);

        const showLocation = document.createElement('li');
        showLocation.classList.add('list-location');
        showLocation.innerHTML = show.location;
        showList.appendChild(showLocation);

        const showCategory = document.createElement('li');
        showCategory.classList.add('list-category');
        showCategory.innerHTML = show.category;
        showList.appendChild(showCategory);

        if (show.exclusive) {
          const showExclusive = document.createElement('li');
          showExclusive.classList.add('label-exclusive');
          showExclusive.innerHTML = 'Exclusive';
          showList.appendChild(showExclusive);
        }

        showLink.appendChild(showList);
        yearBlock.appendChild(showLink);
      });

      this.archive.appendChild(yearBlock);
    });

    const links = this.el.querySelectorAll('a');

    app.highway.attach(links);

    emitter.emit('cursor: init');

    emitter.emit('showsArchive: init');
    emitter.emit('showsArchive: enterShows');
  }
}
