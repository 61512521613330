import { app } from '../../utils/store';

import * as emitter from 'tiny-emitter/instance';

export default class SoundProduction {
  constructor() {
    this.el = document.querySelector('.s-sound-production');

    this.trackTitle = this.el.querySelector('[data-title]');
    this.trackSubTitle = this.el.querySelector('[data-subtitle]');

    this.showTitle = this.el.querySelector('[data-show-title]');
    this.showAssignment = this.el.querySelector('[data-show-assignment]');
    this.showLink = this.el.querySelector('[data-show-link]');

    this.isPlaying = false;
    this.isPaused = true;

    this.next = this.el.querySelector('.next');
    this.prev = this.el.querySelector('.prev');
    this.play = this.el.querySelector('.play');

    this.music = [];
    this.shows = [];
    this.playlist = [];

    this.currentTrack = 0;
  }

  mounted() {
    this.fetch();

    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.next.addEventListener('click', this.nextTrack);
    this.prev.addEventListener('click', this.prevTrack);
    this.play.addEventListener('click', this.playTrack);
  }

  removeEvents = () => {
    this.prev.removeEventListener('click', this.nextTrack);
    this.prev.removeEventListener('click', this.prevTrack);
    this.play.removeEventListener('click', this.playTrack);
  }

  nextTrack = () => {
    this.isPlaying = false;

    if (this.currentTrack === this.playlist.length - 1) {
      this.currentTrack = 0;
    } else {
      this.currentTrack++;
    }

    this.loadTrack();
    this.playTrack();
  }

  prevTrack = () => {
    this.isPlaying = false;

    if (this.currentTrack === 0) {
      this.currentTrack = this.playlist.length - 1;
    } else {
      this.currentTrack--;
    }

    this.loadTrack();
    this.playTrack();
  }

  fetch() {
    if (!app.shows.posts.length || !app.music.posts.length) {
      setTimeout(() => {
        this.fetch();
      }, 100);
    } else {
      this.shows = app.shows.posts;
      this.music = app.music.posts;

      this.music.forEach((track) => {
        const trackID = track.acf.show.ID;
        this.shows.forEach((show) => {
          if (show.id === trackID) {
            const state = {
              trackTitle: track.title.rendered,
              trackSubtitle: track.acf.subtitle,
              trackFile: track.acf.file.url,
              showTitle: show.title.rendered,
              showLink: show.link,
              showAssignment: show.acf.assignment,
            };

            this.playlist.push(state);
          }
        });
      });

      this.loadTrack();
    }
  }

  loadTrack = () => {
    const id = this.currentTrack;

    this.trackTitle.innerHTML = this.playlist[id].trackTitle;
    this.trackSubTitle.innerHTML = this.playlist[id].trackSubtitle;

    this.showTitle.innerHTML = this.playlist[id].showTitle;
    this.showAssignment.innerHTML = this.playlist[id].showAssignment;

    this.showLink.href = this.playlist[id].showLink;

    app.highway.attach(this.showLink);
  }

  playTrack = () => {
    if (!this.isPlaying) {
      const src = this.playlist[this.currentTrack].trackFile;
      emitter.emit('musicPlayer: load', src);
      this.isPlaying = true;
      this.play.classList.add('is-playing');
    } else {
      if (this.isPaused) {
        emitter.emit('musicPlayer: pause');
        this.isPaused = false;
        this.play.classList.remove('is-playing');
      } else {
        emitter.emit('musicPlayer: play');
        this.isPaused = true;
        this.play.classList.add('is-playing');
      }
    }
  }
}
