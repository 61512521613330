export default class HeaderArticle {
  constructor() {
    this.el = document.querySelector('.header-article');

    this.main = document.querySelector('.a-main');

    this.paragraphs = this.main.querySelectorAll('p');
    this.minutes = this.el.querySelector('.minutes');
  }

  mounted() {
    this.getTime();
  }

  destroyed = () => { }

  getTime = () => {
    const text = [];

    this.paragraphs.forEach((paragraph) => {
      text.push(paragraph.innerHTML);
    });
    
    const array = text.join('+');
    const words = array.trim().split(/\s+/).length;

    this.minutes.innerHTML = `${Math.ceil(words / 250)} min`;
  }
}
