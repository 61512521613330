import { app } from '../../utils/store';

export default class SignIn {
  constructor() {
    this.el = document.querySelector('.s-sign-in');

    this.form = this.el.querySelector('form');
  }

  mounted() {
    if (!this.form) {
      app.highway.redirect(`${document.location.origin}/shows/`);
    }
  }

  destroyed = () => { }
}
