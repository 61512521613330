import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class Nav {
  constructor() {
    this.el = document.querySelector('.a-nav');

    this.burger = this.el.querySelector('.burger');
    this.buttons = this.el.querySelector('.buttons');
    this.button = this.el.querySelectorAll('li');
    this.links = this.el.querySelectorAll('a');
  }

  mounted() {
    emitter.on('nav: showBurger', this.showBurger);
    emitter.on('nav: hideBurger', this.hideBurger);

    emitter.on('nav: showButtons', this.showButtons);
    emitter.on('nav: hideButtons', this.hideButtons);

    emitter.on('nav: setActive', this.setActive);

    this.addEvents();
    this.setActive();

    this.showButtons(1);
  }

  addEvents = () => {
    this.burger.addEventListener('click', () => {
      emitter.emit('menu: showMenu');
    });
  }

  showBurger = (delay = 0.5) => {
    gsap.killTweensOf(this.burger);
    gsap.to(this.burger, { autoAlpha: 1, delay });
  }

  hideBurger = () => {
    gsap.killTweensOf(this.burger);
    gsap.to(this.burger, { autoAlpha: 0 });
  }

  showButtons = (delay = 0.5) => {
    gsap.killTweensOf(this.buttons);
    gsap.to(this.buttons, { autoAlpha: 1, delay });
  }

  hideButtons = () => {
    gsap.killTweensOf(this.buttons);
    gsap.to(this.buttons, { autoAlpha: 0 });
  }

  setActive = () => {
    this.button.forEach((el) => {
      el.classList.remove('is-active');

      if (el.querySelector('a').href === location.href) {
        el.classList.add('is-active');
      }
    });
  }
}
