import * as emitter from 'tiny-emitter/instance';

export default class HeaderHome {
  constructor() {
    this.el = document.querySelector('.s-video');
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => {
    this.removeEvents();
  }

  addEvents = () => {
    this.el.addEventListener('click', this.openPlayer);
  }

  removeEvents = () => {
    this.el.removeEventListener('click', this.openPlayer);
  }

  openPlayer = () => {
    const src = this.el.querySelector('[data-video]').dataset.video;

    emitter.emit('video-player: openPlayer', src);
  }
}
