import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class Loader {
  constructor() {
    this.el = document.querySelector('.a-loader');
  }

  mounted() {
    emitter.on('loader: enter', this.enter);
    emitter.on('loader: leave', this.leave);

    this.leave(1);
  }

  enter = (to) => {
    if (to) {
      const sections = to.querySelector('.a-components').children;
      const section = sections[0];

      if (section.hasAttribute('data-light')) {
        this.el.style.background = '#05021d';
      } else {
        this.el.style.background = '#ece5db';
      }

      if (section.hasAttribute('data-secondary')) {
        this.el.style.background = section.dataset.secondary;
      }
    }

    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.5 }
    )
  }

  leave = (delay = 0) => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0, delay, duration: 0.5 }
    )
  }
}
