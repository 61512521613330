import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

import * as emitter from 'tiny-emitter/instance';

import { app } from '../../utils/store';

gsap.registerPlugin(MorphSVGPlugin);

export default class MusicPlayer {
  constructor() {
    this.el = document.querySelector('.a-music-player');

    this.icon = this.el.querySelector('.icon');
    this.background = this.el.querySelector('.background');

    this.svg = this.el.querySelector('svg');
    this.svgPath = this.svg.querySelector('path');

    this.audio = this.el.querySelector('audio');
    this.source = this.el.querySelector('source');

    this.song = 0;
    this.playing = false;
  }

  mounted() {
    this.addEvents();

    this.init();

    emitter.on('musicPlayer: init', this.init);
    emitter.on('musicPlayer: get', this.getTrack);
    emitter.on('musicPlayer: load', this.loadTrack);

    emitter.on('musicPlayer: play', this.playMusic);
    emitter.on('musicPlayer: pause', this.pauseMusic);
    emitter.on('musicPlayer: stop', this.stopMusic);

    emitter.on('musicPlayer: show', this.showPlayer);
    emitter.on('musicPlayer: hide', this.hidePlayer);
  }

  addEvents = () => {
    this.el.addEventListener('click', this.toggleMusic);
  }

  init() {
    if (!app.music.posts.length || !app.shows.posts.length) {
      setTimeout(() => {
        this.init();
      }, 100);
    } else {
      emitter.emit('musicPlayer: get');
    }
  }

  loadTrack = (src) => {
    this.source.src = src;
    this.audio.load();

    this.playMusic();
    this.showPlayer();
  }

  getTrack = () => {
    const page = document.querySelector(['[data-page]']);

    if (!page) {
      this.hidePlayer();
      this.pauseMusic();
      return;
    };

    const showId = Math.floor(page.dataset.page);

    if (!showId) return;

    let songId;
    let fileUrl;

    app.shows.posts.forEach((post) => {
      if (post.id == showId) {
        songId = post.acf.music.ID;
      }
    });

    app.music.posts.forEach((song) => {
      if (song.id == songId) {
        fileUrl = song.acf.file.url;
      }
    });

    if (fileUrl) {
      this.song = fileUrl;
      this.source.src = fileUrl;
      this.audio.load();

      if (this.playing) {
        this.playMusic();
      }
    }

    if (!fileUrl) return;

    this.showPlayer();
  }

  showPlayer = () => {
    gsap.to([this.background, this.icon], { autoAlpha: 1, duration: 1 });
    gsap.fromTo(
      this.svg,
      { xPercent: 0 },
      { xPercent: -70, repeat: -1, ease: 'linear', duration: 0.7 }
    );
  }

  hidePlayer = () => {
    gsap.to([this.background, this.icon], { autoAlpha: 0, duration: 1 });
    gsap.to(this.svg, { xPercent: 0 });
  }

  stopMusic = () => {
    this.pauseMusic();

    this.playing = false;
  }

  pauseMusic = () => {
    gsap.to(this.svgPath, { morphSVG: "M40.26 6.72C36.91 6.72 36.91 4 33.55 4C30.19 4 30.19 6.72 26.84 6.72C23.49 6.72 23.48 4 20.13 4C16.78 4 16.77 6.72 13.42 6.72C10.07 6.72 10.06 4 6.71 4C3.36 4 3.36 6.72 0 6.72" });
    gsap.fromTo(
      this.audio,
      { volume: 1 },
      {
        volume: 0,
        onComplete: () => {
          this.audio.pause();
        }
      }
    );
  }

  playMusic = () => {
    this.audio.play();
    this.playing = true;
    gsap.to(this.svgPath, { morphSVG: "M40.26 9.52005C36.91 9.52005 36.91 0.800049 33.55 0.800049C30.19 0.800049 30.19 9.52005 26.84 9.52005C23.49 9.52005 23.48 0.800049 20.13 0.800049C16.78 0.800049 16.77 9.52005 13.42 9.52005C10.07 9.52005 10.06 0.800049 6.71 0.800049C3.36 0.800049 3.36 9.52005 0 9.52005" });
    gsap.fromTo(
      this.audio,
      { volume: 0 },
      { volume: 1 }
    );
  }

  toggleMusic = () => {
    if (this.audio.paused) {
      this.playMusic();
    } else {
      this.stopMusic();
    }
  }
}
