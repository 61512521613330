import { gsap } from 'gsap';
import Highway from '@dogstudio/highway';

import * as emitter from 'tiny-emitter/instance';

class Default extends Highway.Transition {
  in({ from, to, done }) {
    emitter.emit('loader: enter', to);

    gsap.delayedCall(0.5, () => {
      // remove "from"
      from.remove();

      // init components on "to"
      done();

      // reset scroll on mobile
      window.scrollTo(0, 0);

      // hide loading screen
      emitter.emit('loader: leave');
    });
  }

  out({ from, done }) {
    from.style.pointerEvents = 'none';

    // disable components on "from"
    done();
  }
}

export default Default;
