import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';
import * as emitter from 'tiny-emitter/instance';

import { app } from './store';
import { lerp } from './math';

export default class Scroll {
  constructor() {
    this.el = document.querySelector('[data-scroll]');

    this.last = 0;
    this.ease = 0.1;
    this.current = 0;
    this.top = false;

    this.options = {
      useKeyboard: false,
      touchMultiplier: 3,
      mouseMultiplier: 0.45,
      firefoxMultiplier: 70,
    }

    this.disabled = document.querySelector('[data-scroll-disabled]');

    this.vs = new VirtualScroll(this.options);
  }

  mounted() {
    gsap.ticker.add(this.onRaf);

    if (Sniffer.isDevice || this.disabled) return;

    this.vs.on(this.onScroll);

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();

    // TODO: remove this
    gsap.delayedCall(1, this.onResize);

    emitter.on('scroll: onResize', this.onResize);
  }

  destroyed() {
    gsap.ticker.remove(this.onRaf);

    if (Sniffer.isDevice || this.disabled) return;

    this.vs.off(this.onScroll);
    this.vs.destroy();

    window.removeEventListener('resize', this.onResize, { passive: true });

    emitter.off('scroll: onResize', this.onResize);
  }

  onRaf = () => {
    this.checkTop();

    if (Sniffer.isDevice) {
      this.last = -window.scrollY;
    }

    if (Sniffer.isDevice || this.disabled) return;

    if (-this.last < 0.1) this.last = 0;

    this.last = lerp(this.last, this.current, this.ease);
    this.el.style.transform = `translate3d(0, ${this.last}px, 0)`;
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.bounding = this.el.getBoundingClientRect();
  }

  onScroll = (e) => {
    if (app.menu.visible || app.loading) return;

    this.current += e.deltaY;

    this.current = Math.max((this.bounding.height - this.windowHeight) * -1, this.current);
    this.current = Math.min(0, this.current);
  }

  checkTop = () => {
    if (-this.last > 500 && !this.top) {
      emitter.emit('logo: showMonogram');
      emitter.emit('logo: hideWordmark');

      this.top = true;

      if (Sniffer.isTablet || Sniffer.isDesktop) {
        emitter.emit('nav: hideButtons');
      }

      if (Sniffer.isPhone) return;

      emitter.emit('nav: showBurger');
    }
    if (-this.last < 500 && this.top) {
      emitter.emit('logo: hideMonogram');
      emitter.emit('logo: showWordmark');

      this.top = false;

      if (Sniffer.isTablet || Sniffer.isDesktop) {
        emitter.emit('nav: showButtons');
      }

      if (Sniffer.isPhone) return;

      emitter.emit('nav: hideBurger');
    }
  }
}
