import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

import { inview } from '../../utils/inview';

export default class Background {
  constructor() {
    this.el = document.querySelector('.a-background');
    this.sections = document.querySelectorAll('[data-background');
  }

  mounted() {
    if (this.sections.length > 0) {
      this.el.style.visibility = 'visible';
    }

    gsap.ticker.add(this.onRaf);

    emitter.on('background: reset', this.reset);
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);

    emitter.off('background: reset', this.reset);
  }

  reset = () => {
    gsap.ticker.remove(this.onRaf);

    this.sections = document.querySelectorAll('[data-background');

    gsap.ticker.add(this.onRaf);
  }

  onRaf = () => {
    this.sections.forEach((section) => {
      if (inview(section, window.innerHeight / 2, 0)) {
        if (!section.active) {
          section.active = true;
          this.secondary = section.dataset.secondary;
          this.tertiary = section.dataset.tertiary;
          this.gradient = `radial-gradient(50% 155% at 70% 55%, ${this.secondary} 0%, ${this.tertiary} 100%`;
          this.setColor();
        }
      } else {
        section.active = false;
      }
    });
  }

  setColor() {
    gsap.killTweensOf(this.el);
    gsap.to(this.el, { duration: 0.5, background: this.gradient });
  }
}
