import gsap from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

export default class VideoPlayer {
  constructor() {
    this.el = document.querySelector('.a-video-player');

    this.video = this.el.querySelector('video');
    this.source = this.video.querySelector('source');

    this.range = this.el.querySelector('.range');
    this.progress = this.el.querySelector('.progress');

    this.mute = this.el.querySelector('.mute');
    this.pause = this.el.querySelector('.pause');
    this.close = this.el.querySelector('.close');
    
    this.idle = false;
  }

  mounted() {
    this.addEvents();

    emitter.on('video-player: openPlayer', this.openPlayer);
    emitter.on('video-player: closePlayer', this.closePlayer);
  }

  destroyed = () => { }

  addEvents = () => {
    this.video.addEventListener('canplay', this.onCanPlay);
    this.video.addEventListener('ended', this.onEnded);

    this.mute.addEventListener('click', this.toggleMute);
    this.range.addEventListener('input', this.changeTime);
    this.pause.addEventListener('click', this.togglePause);
    this.close.addEventListener('click', this.closePlayer);

    if (Sniffer.isDevice) return;

    this.video.addEventListener('click', this.closePlayer);
    this.video.addEventListener('mousemove', this.onMouseMove);
  }

  openPlayer = (src) => {
    this.source.src = src;

    this.video.load();
    this.video.play();

    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );

    if (Sniffer.isPhone) {
      this.video.setAttribute('controls', 'controls');
    }

    if (Sniffer.isDevice) return;

    emitter.emit('musicPlayer: pause');

    gsap.ticker.add(this.onRaf);

    gsap.delayedCall(1.5, this.onIdle);
  }

  closePlayer = () => {
    this.video.pause();

    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0 }
    );

    if (Sniffer.isDevice) return;

    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    this.setTime();
  }

  onMouseMove = () => {
    if (!this.idle) {
      this.idle = true;
      this.el.classList.remove('is-idle');
    }
    if (this.idle) {
      this.idle = false;
      gsap.killTweensOf(this.onIdle);
      gsap.delayedCall(1.5, this.onIdle);
    }
  }

  onIdle = () => {
    this.el.classList.add('is-idle');
  }

  onCanPlay = () => {
    if (this.video.duration) {
      this.range.max = Math.round(this.video.duration * 10);
      this.progress.max = Math.round(this.video.duration * 10);
    }
  }

  togglePause = () => {
    if (this.video.paused) {
      this.video.play();
      this.pause.innerHTML = 'Pause';
    } else {
      this.video.pause();
      this.pause.innerHTML = 'Play';
    }
  }

  toggleMute = () => {
    if (this.video.muted) {
      this.video.muted = false;
      this.mute.innerHTML = 'Mute';
    } else {
      this.video.muted = true;
      this.mute.innerHTML = 'Unmute';
    }
  }

  onEnded = () => {
    this.closePlayer();
  }

  setTime = () => {
    this.videoCurrentTime = this.video.currentTime;

    this.range.value = this.video.currentTime * 10;
    this.progress.value = this.video.currentTime * 10;
  }

  changeTime = () => {
    this.video.currentTime = this.range.value / 10;
    this.setTime();
  }
}
