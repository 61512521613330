import { gsap } from 'gsap';

import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class Media {
  constructor() {
    this.el = document.querySelectorAll('.s-media');

    this.timeline = [];
  }

  mounted() {
    this.onResize();

    gsap.ticker.add(this.onRaf);
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    this.el.forEach((el, i) => {
      if (inview(el, window.innerHeight, 0)) {
        this.setProgress(el, i);
      }
    });
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.setAnimation();
  }

  setProgress = (el, i) => {
    const bounds = el.getBoundingClientRect();

    const value = -bounds.top + this.windowHeight;
    const max = bounds.height + this.windowHeight;

    this.progress = progress(value, 0, max);

    this.timeline[i].progress(this.progress);
  }

  setAnimation = () => {
    this.el.forEach((el) => {
      const scrollTl = gsap.timeline({ paused: true, defaults: { ease: 'none' } });
      const container = el.querySelector('.container');
      const media = container.children;

      scrollTl
        .fromTo(
          media[1],
          { yPercent: -50 },
          { yPercent: 0 }
        );

      this.timeline.push(scrollTl);
    });
  }
}
