import gsap from 'gsap';
import Sniffer from 'sniffer';
import VirtualScroll from 'virtual-scroll';

import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class ShowsFeatured {
  constructor() {
    this.el = document.querySelector('.s-shows-featured');

    this.info = this.el.querySelector('.info');
    this.wrapper = this.el.querySelector('.wrapper');
    this.container = this.el.querySelector('.container');

    this.keyvisual = this.el.querySelector('[data-keyvisual]');

    const options = {
      mouseMultiplier: 0.2,
    }

    this.intro = true;

    this.vs = new VirtualScroll(options);

    this.last = 0;
    this.ease = 0.1;
    this.current = 0;
  }

  mounted() {
    emitter.on('showsFeatured: enter', this.enter);
    emitter.on('showsFeatured: leave', this.leave);

    if (Sniffer.isDevice) return;

    this.addEvents();

    gsap.ticker.add(this.onRaf);

    this.vs.on(this.onScroll);

    gsap.delayedCall(0.1, () => {
      emitter.emit('shows: setDefault');

      this.onResize();
      this.onEnter();
    });
  }

  destroyed = () => {
    emitter.off('showsFeatured: enter', this.enter);
    emitter.off('showsFeatured: leave', this.leave);

    if (Sniffer.isDevice) return;

    this.removeEvents();

    gsap.ticker.remove(this.onRaf);

    this.vs.destroy();
  }

  onEnter = () => {
    gsap.fromTo(
      this.wrapper,
      { autoAlpha: 0, x: this.windowWidth / 2 },
      { autoAlpha: 1, x: 0, ease: 'expo.out', duration: 1.5, onComplete: () => { this.intro = false; } }
    );
  }

  enter = () => {
    if (Sniffer.isDesktop || Sniffer.isTablet) {
      this.enterDesktop();
      this.vs.on(this.onScroll);
    } else {
      this.enterMobile();
    }
  }

  leave = () => {
    if (Sniffer.isDesktop || Sniffer.isTablet) {
      this.leaveDesktop();
      this.vs.off(this.onScroll);
    } else {
      this.leaveMobile();
    }
  }

  enterDesktop = () => {
    gsap.fromTo(
      [
        this.info,
        this.container,
        this.keyvisual.querySelectorAll('.mask')
      ],
      { autoAlpha: 0, x: -this.windowWidth },
      { autoAlpha: 1, x: 0, ease: 'expo.inOut', duration: 1.5 }
    );

    gsap.fromTo(
      [
        this.keyvisual.querySelectorAll('.layer')
      ],
      { x: -this.windowWidth },
      { x: 0, ease: 'expo.inOut', duration: 1.5 }
    );

    this.el.style.pointerEvents = '';
  }

  leaveDesktop = () => {
    gsap.fromTo(
      [
        this.info,
        this.container,
        this.keyvisual.querySelectorAll('.mask')
      ],
      { autoAlpha: 1, x: 0 },
      { autoAlpha: 0, x: -this.windowWidth, ease: 'expo.inOut', duration: 1.5 }
    );

    gsap.fromTo(
      [
        this.keyvisual.querySelectorAll('.layer')
      ],
      { x: 0 },
      { x: -this.windowWidth, ease: 'expo.inOut', duration: 1.5 }
    );

    this.el.style.pointerEvents = 'none';
  }

  enterMobile = () => {
    gsap.fromTo(
      [
        this.el.querySelector('.h2'),
        this.el.querySelectorAll('.content'),
        this.el.querySelectorAll('.layer'),
        this.el.querySelectorAll('.mask')
      ],
      { autoAlpha: 0, x: -window.innerWidth },
      { autoAlpha: 1, x: 0, ease: 'expo.inOut', duration: 1.5 }
    );
  }

  leaveMobile = () => {
    gsap.fromTo(
      [
        this.el.querySelector('.h2'),
        this.el.querySelectorAll('.content'),
        this.el.querySelectorAll('.layer'),
        this.el.querySelectorAll('.mask')
      ],
      { autoAlpha: 1, x: 0 },
      { autoAlpha: 0, x: -window.innerWidth, ease: 'expo.inOut', duration: 1.5 }
    );
  }

  addEvents = () => {
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  removeEvents = () => {
    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onResize = () => {
    this.windowWidth = window.innerWidth;

    const children = this.wrapper.children;

    let width = 0;

    for (let i = 0; i < children.length; i++) {
      width += parseInt(children[i].offsetWidth, 10);
    }

    this.wrapper.style.width = width + this.windowWidth * 0.1 + 'px';

    this.bounding = this.wrapper.getBoundingClientRect();
  }

  onScroll = (e) => {
    if (this.intro) return;

    this.current += e.deltaY;
    this.current = Math.max((this.bounding.width - this.windowWidth) * -1, this.current);
    this.current = Math.min(0, this.current);
  }

  onRaf = () => {
    if (this.intro) return;

    if (-this.last < 0.1) this.last = 0;

    this.last = lerp(this.last, this.current, this.ease);

    this.wrapper.style.transform = `translate3d(${this.last}px, 0px, 0px)`;
  }
}
