import gsap from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class Shows {
  constructor() {
    this.el = document.querySelector('.s-shows-select');

    this.info = this.el.querySelector('.info');
    this.infoBlock = this.el.querySelector('.info-block');

    this.header = document.querySelector('.header-show');
    this.background = document.querySelector('.a-background');

    this.inview = false;
  }

  mounted() {
    if (Sniffer.isDevice) return;

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();

    gsap.ticker.add(this.onRaf);

    this.setAnimation();
  }

  destroyed = () => {
    if (Sniffer.isDevice) return;

    window.removeEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.remove(this.onRaf);
  }

  setAnimation = () => {
    this.bounds = this.el.getBoundingClientRect();

    this.scrollTl = gsap.timeline({
      paused: true,
      defaults: {
        ease: 'linear',
      },
    });

    this.scrollTl
      .fromTo(
        this.info,
        { y: 0 },
        { y: this.bounds.height - this.windowHeight - (this.windowWidth * 0.2) },
        0,
      );
  }

  setProgress = () => {
    this.bounds = this.el.getBoundingClientRect();
    this.progress = progress(this.bounds.top, 0, this.bounds.height - this.windowHeight - (this.windowWidth * 0.2));
    this.scrollTl.progress(-this.progress);
  }

  onRaf = () => {
    if (inview(this.el, this.windowHeight / 2, 0)) {
      if (!this.inview) {
        this.inview = true;
        emitter.emit('shows: setDefault');
      }
    } else if (this.inview) {
      this.inview = false;

      this.resetBackground();
    }

    if (inview(this.el, this.windowHeight, 0)) {
      this.setProgress();
    }
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    this.setAnimation();
  }

  resetBackground = () => {
    if (!this.header) return;

    const gradient = `radial-gradient(50% 155% at 70% 55%, ${this.header.dataset.secondary} 0%, ${this.header.dataset.tertiary} 100%`;

    gsap.killTweensOf(this.background);
    gsap.to(this.background, { duration: 0.5, background: gradient });
  }
}
