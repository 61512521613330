import { gsap } from 'gsap';

import { inview } from '../../utils/inview';

export default class Text {
  constructor(el) {
    this.el = el;

    this.container = this.el.querySelector('.container');
  }

  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.add(this.onRaf);

    this.onResize();
  }

  destroyed = () => {
    window.removeEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.remove(this.onRaf);
  }

  onResize = () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  onRaf = () => {
    if (inview(this.el, this.windowHeight / 1.2, 0)) {
      this.enter();
      this.destroyed();
    }
  }

  enter = () => {
    gsap.fromTo(
      this.container.children,
      { autoAlpha: 0, y: window.innerWidth * 0.02 },
      { autoAlpha: 1, y: 0, duration: 2, stagger: 0.2, ease: 'expo.out' }
    );
  }
}
