import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import * as emitter from 'tiny-emitter/instance';

import { lerp } from '../../utils/math';

export default class Menu {
  constructor() {
    this.el = document.querySelector('.a-menu');

    this.links = this.el.querySelectorAll('a');
    this.close = this.el.querySelector('.close');
    this.main = this.el.querySelectorAll('.main a');
    this.title = this.el.querySelectorAll('.title');
    this.sub = this.el.querySelectorAll('.block li');
    this.social = this.el.querySelectorAll('.social-links a')
    this.eyes = this.el.querySelectorAll('.eyes-of-plugged svg');

    this.disable = false;
    this.visible = false;

    this.last = 0;
    this.current = 0;
    this.ease = 0.01;
  }

  mounted() {
    emitter.on('menu: showMenu', this.enter);
    emitter.on('menu: hideMenu', this.leave);

    this.addEvents();

    if (Sniffer.isDevice) return;

    this.onResize();
    this.setAnimation();
  }

  onRaf = () => {
    this.last = lerp(this.last, this.current, this.ease);

    this.eyesTl.progress(this.last);
  }

  onResize = () => {
    this.windowWidth = window.innerWidth;

    this.setAnimation();
  }

  addEvents = () => {
    this.close.addEventListener('click', () => {
      emitter.emit('menu: hideMenu');
    });

    this.links.forEach((e) => {
      e.addEventListener('click', this.leave);
    });

    if (Sniffer.isDevice) return;

    this.close.addEventListener('mouseenter', this.mouseEnterClose);

    this.main.forEach(main => main.addEventListener('mouseenter', this.mouseEnterMain));
    this.main.forEach(main => main.addEventListener('mouseleave', this.mouseLeaveMain));

    window.addEventListener('resize', this.onResize, { passive: true });
  }

  setAnimation = () => {
    this.eyesTl = gsap.timeline({ paused: true, defaults: { ease: 'none' } });
    this.eyesTl
      .fromTo(
        this.eyes[0],
        { rotate: 45 },
        { rotate: -45 }, 0
      )
      .fromTo(
        this.eyes[1],
        { rotate: -45 },
        { rotate: 45 }, 0
      );
  }

  mouseEnterClose = () => {
    gsap.fromTo(
      this.close,
      { rotate: 0 },
      { rotate: 90, duration: 0.5 }
    );
  }

  mouseEnterMain = (el) => {
    if (this.disable) return;

    gsap.killTweensOf(this.main);

    gsap.to(this.main, { opacity: 0.2 });
    gsap.to(el.target, { opacity: 1 });
  }

  mouseLeaveMain = () => {
    if (this.disable) return;

    gsap.killTweensOf(this.main);

    gsap.to(this.main, { opacity: 1 });
  }

  onMouseMove = (e) => {
    this.current = e.clientX / this.windowWidth;
  }

  enter = () => {
    if (this.disable) return;

    this.el.style.pointerEvents = '';

    if (Sniffer.isDesktop) {
      window.addEventListener('mousemove', this.onMouseMove);

      gsap.ticker.add(this.onRaf);
    }

    this.disable = true;
    this.visible = true;

    gsap.to(this.el, { autoAlpha: 1 });

    gsap.killTweensOf(this.main);

    gsap.fromTo(
      this.main,
      { autoAlpha: 0, yPercent: 50 },
      { autoAlpha: 1, yPercent: 0, ease: 'expo.out', stagger: 0.1, duration: 1.5 }
    );

    gsap.fromTo(
      [this.title, this.sub, this.close, this.social],
      { autoAlpha: 0, yPercent: 50 },
      { autoAlpha: 1, yPercent: 0, ease: 'expo.out', stagger: 0.05, duration: 1 }
    );

    gsap.fromTo(
      this.eyes[0],
      { autoAlpha: 0, yPercent: -100 },
      { autoAlpha: 1, yPercent: 0, ease: 'expo.out', duration: 1.5 }
    );

    gsap.fromTo(
      this.eyes[1],
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, ease: 'expo.out', duration: 1.5 }
    );

    gsap.delayedCall(1.5, () => {
      this.disable = false;
    });

    document.body.classList.add('is-light');
  }

  leave = () => {
    if (this.disable) return;

    this.el.style.pointerEvents = 'none';

    if (Sniffer.isDesktop) {
      window.removeEventListener('mousemove', this.onMouseMove);

      gsap.ticker.remove(this.onRaf);
    }

    this.disable = true;

    gsap.to(this.el, { autoAlpha: 0, delay: 2 });

    gsap.killTweensOf(this.main);

    gsap.to(
      this.main,
      { autoAlpha: 0, yPercent: -50, ease: 'expo.in', stagger: 0.1, duration: 1 }
    );

    gsap.fromTo(
      [this.title, this.sub, this.close, this.social],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -50, ease: 'expo.in', stagger: 0.05, duration: 0.5 }
    );

    gsap.fromTo(
      this.eyes[0],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: -100, ease: 'expo.in', duration: 1 }
    );

    gsap.fromTo(
      this.eyes[1],
      { autoAlpha: 1, yPercent: 0 },
      { autoAlpha: 0, yPercent: 100, ease: 'expo.in', duration: 1 }
    );

    gsap.delayedCall(2, () => {
      this.visible = false;
    });

    gsap.delayedCall(3, () => {
      this.disable = false;
    });
  }
}
