import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

export default class Logo {
  constructor() {
    this.el = document.querySelector('.a-logo');

    this.monogram = this.el.querySelector('.monogram');
    this.wordmark = this.el.querySelector('.wordmark');

    this.top = false;
  }

  mounted() {
    emitter.on('logo: showMonogram', this.showMonogram);
    emitter.on('logo: hideMonogram', this.hideMonogram);

    emitter.on('logo: showWordmark', this.showWordmark);
    emitter.on('logo: hideWordmark', this.hideWordmark);

    this.showWordmark(1);
  }

  showMonogram = (delay = 0.5) => {
    gsap.killTweensOf(this.monogram);
    gsap.to(this.monogram, { autoAlpha: 1, delay });
  }

  hideMonogram = () => {
    gsap.killTweensOf(this.monogram);
    gsap.to(this.monogram, { autoAlpha: 0 });
  }

  showWordmark = (delay = 0.5) => {
    gsap.killTweensOf(this.wordmark);
    gsap.to(this.wordmark, { autoAlpha: 1, delay });
  }

  hideWordmark = () => {
    gsap.killTweensOf(this.wordmark);
    gsap.to(this.wordmark, { autoAlpha: 0 });
  }
}
