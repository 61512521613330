import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

import { app } from '../../utils/store';

export default class ShowsNav {
  constructor() {
    this.el = document.querySelector('.s-insights-nav');

    this.newsletter = document.querySelector('.s-newsletter');
    this.insightsArchive = document.querySelectorAll('.s-insights-archive');

    this.buttons = this.el.querySelectorAll('.item');

    this.active = this.el.querySelector('.active');
    this.label = [
      'All',
      'Articles',
      'News'
    ]

    this.index = 0;
  }

  mounted() {
    this.addEvents();
  }

  destroyed = () => { }

  addEvents = () => {
    this.buttons.forEach((button, i) => {
      button.addEventListener('click', () => {
        this.filterAnimation(i);
        this.filterList(i);
      });
    });
  }

  filterAnimation = (i) => {
    gsap.delayedCall(0.75, () => {
      this.active.innerHTML = this.label[i];
    });
    gsap.to(
      this.active,
      {
        duration: 1.5,
        ease: 'expo.inOut',
        x: this.buttons[i].offsetLeft,
        width: this.buttons[i].clientWidth
      }
    );
  }

  filterList = (i) => {
    if (i === this.index) return;

    let id;

    if (i === 1) {
      id = 5;
    } else if (i === 2) {
      id = 6;
    } else {
      id = 0;
    }

    this.index = i;

    const insights = app.insights.posts;

    this.insights = [];

    insights.forEach((insight) => {
      const props = {
        id: insight.id,
        link: insight.link,
        date: insight.date,
        image: insight.acf.image.url,
        excerpt: insight.acf.excerpt,
        title: insight.title.rendered,
      }

      if (id === 0) {
        this.insights.push(props);
        return;
      } else if (id === insight['category-insight'][0]) {
        this.insights.push(props);
      }
    });

    emitter.emit('insightsPost: reset');

    this.removePosts();

    gsap.fromTo(
      this.insightsArchive[0],
      { autoAlpha: 1 },
      { autoAlpha: 0, onComplete: this.appendPosts }
    );
  }

  removePosts = () => {
    if (this.newsletter) {
      this.newsletter.remove();
      this.newsletter = undefined;
    }
    if (this.insightsArchive[1]) {
      this.insightsArchive[1].remove();
    }
  }

  appendPosts = () => {
    const container = this.insightsArchive[0].querySelector('.container');
    container.innerHTML = '';

    this.insights.forEach((el) => {
      const insight = document.createElement('div');
      insight.classList.add('insights-post');

      const post = `
        <a href="${el.link}" class="post" data-cursor="image">
          <div class="image">
            <img class="img" alt="" src="${el.image}">
          </div>
          <div class="content">
            <h3 class="title">${el.title}</h3>
            <p class="excerpt">${el.excerpt}</p>
          </div>
        </a>
      `;

      insight.innerHTML = post;

      container.appendChild(insight);
    });

    gsap.fromTo(
      this.insightsArchive[0],
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );

    emitter.emit('scroll: onResize');
    emitter.emit('insightsPost: init');
  }
}
