import gsap from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

import { app } from '../../utils/store';

export default class Shows {
  constructor() {
    this.el = document.querySelector('.s-shows');

    this.infoBlock = this.el.querySelector('.info-block');

    this.show = this.el.querySelectorAll('.show');
    this.placeholder = this.el.querySelector('.placeholder');

    this.title = this.el.querySelector('[data-info="title"]');
    this.text = this.el.querySelector('[data-info="text"]');
    this.keyvisual = this.el.querySelector('[data-keyvisual]');

    this.background = document.querySelector('.a-background');
    this.nav = document.querySelector('.a-shows-nav');
  }

  mounted() {
    if (Sniffer.isPhone) {
      this.setBackground();
    }

    this.addEvents();

    if (Sniffer.isDevice) return;

    if (this.el.classList.contains('s-shows-select')) {
      this.showsSelect = true;
    }

    this.getShows();
    this.getDefault();

    emitter.on('shows: setDefault', this.setDefault);
  }

  destroyed = () => {
    if (Sniffer.isDevice) return;

    emitter.off('shows: setDefault', this.setDefault);
  }

  addEvents = () => {
    this.show.forEach((show, i) => {
      show.addEventListener('click', () => this.onClick(show));

      if (Sniffer.isDevice) return;

      show.addEventListener('mouseenter', () => this.onMouseEnter(show, i));
      show.addEventListener('mouseleave', this.onMouseLeave);
    });
  }

  setDefault = () => {
    this.setShow(this.show.length);
  }

  setBackground = () => {
    this.show.forEach((show) => {
      show.setAttribute('data-background', '');
    });

    emitter.emit('background: reset');
  }

  onMouseEnter = (e, i) => {
    gsap.to(this.show, { autoAlpha: 0.2 });
    gsap.to(e, { autoAlpha: 1 });

    this.setShow(i);
  }

  onMouseLeave = () => {
    if (this.disable) return;

    gsap.to(this.show, { autoAlpha: 1 });

    const i = this.show.length;

    this.setShow(i);
  }

  onClick = (show) => {
    this.disable = true;

    if (Sniffer.isPhone) {
      app.highway.redirect(show.querySelector('a').href);
    }
  }

  setShow = (i) => {
    const { category, description, keyvisual, primary, secondary, tertiary } = this.shows[i];

    this.keyvisual.innerHTML = keyvisual;

    this.title.style.setProperty('--color-primary', primary);

    if (this.nav) {
      this.nav.style.setProperty('--color-secondary', secondary);
    }

    this.gradient = `radial-gradient(50% 155% at 70% 55%, ${secondary} 0%, ${tertiary} 100%`;

    gsap.to(
      this.background,
      { duration: 0.5, background: this.gradient }
    );

    if (i === this.show.length && this.showsSelect) {
      gsap.killTweensOf(this.infoBlock);
      gsap.to(this.infoBlock, { autoAlpha: 0 });
    } else {
      this.title.innerHTML = category;
      this.text.innerHTML = description;

      gsap.fromTo(
        this.infoBlock,
        { autoAlpha: 0, yPercent: 10 },
        { autoAlpha: 1, yPercent: 0, duration: 1.5, ease: 'expo.out' }
      );
    }

    gsap.fromTo(
      this.keyvisual.querySelector('.image'),
      { autoAlpha: 0, scale: 1.2 },
      { scale: 1, autoAlpha: 1, duration: 1.5, ease: 'expo.out' }
    );

    if (i === this.show.length) {
      gsap.set(
        [
          this.keyvisual.querySelectorAll('.layer')[0],
          this.keyvisual.querySelectorAll('.layer')[1],
        ],
        { opacity: 0 },
      );

      return;
    };

    gsap.fromTo(
      this.keyvisual.querySelectorAll('.layer')[0],
      { opacity: 0, yPercent: 10 },
      { opacity: 1, yPercent: 0, duration: 1.5, ease: 'expo.out' }
    );

    gsap.fromTo(
      this.keyvisual.querySelectorAll('.layer')[1],
      { opacity: 0, yPercent: -10 },
      { opacity: 1, yPercent: 0, duration: 1.5, ease: 'expo.out' }
    );
  }

  getShows = () => {
    this.shows = Array.from(this.show).map(show => ({
      category: show.querySelector('.category').innerHTML,
      description: show.querySelector('.description').innerHTML,
      keyvisual: show.querySelector('.keyvisual').innerHTML,
      primary: show.dataset.primary,
      secondary: show.dataset.secondary,
      tertiary: show.dataset.tertiary
    }));
  }

  getDefault = () => {
    this.shows.push({
      category: 'Plugged Live Shows',
      description: '26 Shows with over 25M+ vistors across 18 different countries.',
      keyvisual: this.placeholder.querySelector('.keyvisual').innerHTML,
      primary: '#5252c6',
      secondary: '#01017e',
      tertiary: '#040232'
    });
  }
}
