import { gsap } from 'gsap';

export default class Notification {
  constructor() {
    this.el = document.querySelector('.a-notification');

    this.accept = this.el.querySelector('.accept');
    this.decline = this.el.querySelector('.decline');

    this.cookie = false;
    this.gtmId = 'GTM-PZ4G256';
  }

  mounted() {
    this.accept.addEventListener('click', this.enable);
    this.decline.addEventListener('click', this.disable);

    if (document.cookie.match(/^(.*;)?\s*_ga\s*=\s*[^;]+(.*)?$/)) {
      this.cookie = true;
      this.enable();
    } else {
      this.enter();
    }
  }

  enable = () => {
    const gtmHead = document.createElement('script');

    gtmHead.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${this.gtmId}')`;

    document.head.appendChild(gtmHead);

    if (this.cookie) {
      this.el.remove(); return;
    };

    this.leave();
  }

  disable = () => {
    this.leave();
  }

  enter = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    )
  }

  leave = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 1 },
      { autoAlpha: 0, onComplete: () => { this.el.remove() } }
    )
  }
}
