import { gsap } from 'gsap';

import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class Dragger {
  constructor() {
    this.el = document.querySelector('.s-collage');

    this.image = this.el.querySelectorAll('.image');
  }

  mounted() {
    window.addEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.add(this.onRaf);

    this.onResize();
  }

  destroyed = () => {
    window.removeEventListener('resize', this.onResize, { passive: true });

    gsap.ticker.remove(this.onRaf);
  }

  onRaf = () => {
    if (inview(this.el, this.windowHeight, 0)) {
      this.setProgress();
    }
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.setAnimation();
  }

  setProgress = () => {
    const bounds = this.el.getBoundingClientRect();

    const value = -bounds.top + this.windowHeight;
    const max = bounds.height + this.windowHeight;

    this.progress = progress(value, 0, max);
    this.scrollTl.progress(this.progress);
  }

  setAnimation = () => {
    this.scrollTl = gsap.timeline({ paused: true, defaults: { ease: 'linear' } });

    this.scrollTl
      .fromTo(
        this.image[1],
        { yPercent: 0 },
        { yPercent: -50 }, 0
      )
      .fromTo(
        this.image[2],
        { yPercent: 0 },
        { yPercent: 50 }, 0
      )
      .fromTo(
        this.image[4],
        { yPercent: 0 },
        { yPercent: -50 }, 0
      )
      .fromTo(
        this.image[5],
        { yPercent: 50 },
        { yPercent: 0 }, 0
      );
  }
}
