import { gsap } from 'gsap';

import { collision } from './collision';
import { inview } from './inview';
import { app } from './store';

export default class Hud {
  constructor() {
    this.logo = document.querySelector('.a-logo');
  }

  mounted() {
    this.getSections();

    gsap.ticker.add(this.onRaf);
  }

  destroyed() {
    this.dark = [];
    this.light = [];

    gsap.ticker.remove(this.onRaf);
  }

  getSections() {
    this.dark = document.querySelectorAll('[data-dark]');
    this.light = document.querySelectorAll('[data-light]');
  }
  
  onRaf = () => {
    if (app.menu.visible) return;

    this.light.forEach((section) => {
      if (inview(section, window.innerHeight, 0)) {
        if (collision(section, this.logo)) {
          document.body.classList.add('is-light');
        }
      }
    });
    
    this.dark.forEach((section) => {
      if (inview(section, window.innerHeight, 0)) {
        if (collision(section, this.logo)) {
          document.body.classList.remove('is-light');
        }
      }
    });
  }
}
