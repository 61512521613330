import { gsap } from 'gsap';
import Sniffer from 'sniffer';

import * as emitter from 'tiny-emitter/instance';

export default class ShowsNav {
  constructor() {
    this.el = document.querySelector('.a-shows-nav');

    this.showsFeatured = document.querySelector('.s-shows');
    this.showsArchive = document.querySelector('.s-shows-archive');

    this.buttons = this.el.querySelectorAll('.item');

    this.active = this.el.querySelector('.active');
    this.label = [
      'Featured',
      'All'
    ]

    this.index = 0;
  }

  mounted() {
    this.addEvents();

    if (Sniffer.isPhone) return;

    this.enter();
  }

  enter = () => {
    gsap.fromTo(
      this.el,
      { autoAlpha: 0, yPercent: 100 },
      { autoAlpha: 1, yPercent: 0, ease: 'expo.out', duration: 1.5 }
    )
  }

  destroyed = () => { }

  addEvents = () => {
    this.buttons.forEach((button, i) => {
      button.addEventListener('click', () => {
        this.filterAnimation(i);
        this.filter(i);
      });
    });
  }

  filterAnimation = (i) => {
    gsap.delayedCall(0.75, () => {
      this.active.innerHTML = this.label[i];
    });
    gsap.to(
      this.active,
      {
        duration: 1.5,
        ease: 'expo.inOut',
        x: this.buttons[i].offsetLeft,
        width: this.buttons[i].clientWidth
      }
    );
  }

  filter = (i) => {
    if (i === this.index) return;

    this.index = i;

    if (i === 0) {
      emitter.emit('showsFeatured: enter');
      emitter.emit('showsArchive: leave');
      emitter.emit('showsFilter: leave');

      if (Sniffer.isPhone) {
        gsap.fromTo(
          this.el,
          { x: -window.innerWidth * 0.18 },
          { x: 0, duration: 1.5, ease: 'expo.inOut' }
        );
      }
    } else {
      emitter.emit('showsFeatured: leave');
      emitter.emit('showsArchive: enter');
      emitter.emit('showsFilter: enter');

      if (Sniffer.isPhone) {
        gsap.fromTo(
          this.el,
          { x: 0 },
          { x: -window.innerWidth * 0.18, duration: 1.5, ease: 'expo.inOut' }
        );
      }
    }
  }
}
