import { gsap } from 'gsap';
import * as emitter from 'tiny-emitter/instance';

import { inview } from '../../utils/inview';

export default class InsightsPost {
  mounted() {
    this.init();

    emitter.on('insightsPost: init', this.init);
    emitter.on('insightsPost: reset', this.reset);
  }

  init = () => {
    this.insight = document.querySelectorAll('.insights-post');

    this.count = 0;

    gsap.ticker.add(this.onRaf);

    window.addEventListener('resize', this.onResize, { passive: true });

    this.onResize();
  }

  destroyed = () => {
    gsap.ticker.remove(this.onRaf);

    window.removeEventListener('resize', this.onResize, { passive: true });

    emitter.off('insightsPost: init', this.init);
    emitter.off('insightsPost: reset', this.reset);
  }

  reset = () => {
    gsap.ticker.remove(this.onRaf);

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;
  }

  onRaf = () => {
    this.insight.forEach((el, i) => {
      if (inview(el, this.windowHeight / 1.1, 0)) {
        if (!el.inview) {
          el.inview = true;

          const rand = Math.floor(Math.random() * 3);

          gsap.fromTo(
            el,
            { autoAlpha: 0, yPercent: 25 },
            { autoAlpha: 1, yPercent: 0, ease: 'expo.out', duration: 2, delay: 0.1 * rand }
          );

          this.count++;
        }
      }
    });

    if (this.count === this.insight.length) {
      this.reset();
    }
  }
}
