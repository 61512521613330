import { gsap } from 'gsap';
import Sniffer from 'sniffer';
import * as emitter from 'tiny-emitter/instance';

import { app } from '../../utils/store';
import { progress } from '../../utils/math';
import { inview } from '../../utils/inview';

export default class HeaderHome {
  constructor() {
    this.el = document.querySelector('.header-home');

    this.text = this.el.querySelector('.text');
    this.video = this.el.querySelector('.video');
    this.waves = this.el.querySelectorAll('.waves path');
    this.heading = this.el.querySelectorAll('.heading g');
    this.button = this.el.querySelector('.button');
  }

  mounted() {
    this.addEvents();

    this.enter();

    app.loading = true;

    gsap.delayedCall(2, () => {
      app.loading = false;

      this.onResize();

      gsap.ticker.add(this.onRaf);
    })
  }

  destroyed = () => {
    this.removeEvents();

    gsap.ticker.remove(this.onRaf);
  }

  addEvents = () => {
    if (Sniffer.isPhone) {
      this.button.addEventListener('click', this.openPlayer);
    } else {
      this.el.addEventListener('click', this.openPlayer);
    }

    window.addEventListener('resize', this.onResize, { passive: true });
  }

  removeEvents = () => {
    if (Sniffer.isPhone) {
      this.button.removeEventListener('click', this.openPlayer);
    } else {
      this.el.removeEventListener('click', this.openPlayer);
    }

    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  openPlayer = () => {
    const src = this.el.querySelector('[data-video]').dataset.video;

    emitter.emit('video-player: openPlayer', src);
  }

  onRaf = () => {
    if (inview(this.el, window.innerHeight, 0)) {
      this.setProgress();
    }
  }

  onResize = () => {
    this.windowHeight = window.innerHeight;

    this.setAnimation();
  }

  setProgress = () => {
    const bounds = this.el.getBoundingClientRect();

    const value = -bounds.top;
    const max = bounds.height;

    this.progress = progress(value, 0, max);

    this.videoTl.progress(this.progress);
    this.wavesTl.progress(this.progress);
  }

  setAnimation = () => {
    this.videoTl = gsap.timeline({ paused: true });
    this.wavesTl = gsap.timeline({ paused: true });

    this.videoTl
      .fromTo(
        this.waves,
        { yPercent: 0, autoAlpha: 1 },
        { yPercent: -50, autoAlpha: 0, stagger: -0.05 }
      );

    this.wavesTl
      .fromTo(
        this.video.querySelector('video'),
        { yPercent: 0, opacity: 1 },
        { yPercent: 50, opacity: 0 }
      );
  }

  enter = () => {
    gsap.fromTo(
      this.video,
      { autoAlpha: 0 },
      { autoAlpha: 0.9, delay: 1, duration: 3 }
    );

    gsap.fromTo(
      [this.heading, this.text, this.button],
      { xPercent: 20, autoAlpha: 0 },
      { xPercent: 0, autoAlpha: 1, delay: 1, stagger: 0.2, duration: 2, ease: 'expo.out' }
    );

    gsap.fromTo(
      this.waves,
      { yPercent: 100, autoAlpha: 0 },
      { yPercent: 0, autoAlpha: 1, stagger: -0.05, duration: 2, ease: 'expo.out' }
    );
  }
}
